const columns = [
  {
    label: "日期",
    prop: "date",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: "left",
  },
  {
    label: "直播时长(H)",
    prop: "duration",
    align: "center",
    width: 150,
    tooltip: "达人直播的总小时数",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "总GMV($)",
    prop: "gmv_amount",
    align: "center",
    width: 150,
    tooltip: "统计周期内，绑定达人的闭环订单收入",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "直播GMV($)",
    prop: "live_gmv_amount",
    align: "center",
    width: 150,
    tooltip: "为达人直播产生的成交金额",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "TAP GMV($)",
    prop: "estimated_commission_amount",
    align: "center",
    width: 170,
    tooltip: "达人TAP 预估佣金总额",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "投流 GMV($)",
    prop: "investment_flow",
    align: "center",
    width: 170,
    tooltip: "投流带来的 GMV",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "直播小时 GMV($)",
    prop: "live_gmv_amount_by_hour",
    align: "center",
    width: 180,
    tooltip: "直播 GMV / 直播时长",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "直播客单价($)",
    prop: "customer_price",
    align: "center",
    width: 220,
    tooltip: "直播GMV/直播订单成交数",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "直播订单成交数",
    prop: "live_order_cnt",
    align: "center",
    width: 220,
    tooltip: "达人在直播中产生的订单数",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "直播商品成交件数",
    prop: "live_sales",
    align: "center",
    width: 220,
    tooltip: "达人在直播中累计成交的商品数量",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "ROAS",
    prop: "roas",
    align: "center",
    width: 120,
    tooltip: "投流GMV/投流消耗",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "投流消耗($)",
    prop: "input_cost",
    align: "center",
    width: 150,
    tooltip: "投流的总消耗金额",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "预估MCN佣金($)",
    prop: "mcn_est_commission_amount",
    align: "center",
    width: 200,
    tooltip: "预估 MCN 佣金",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "实时TAP佣金($)",
    prop: "tap_actual_commission_amount",
    align: "center",
    width: 200,
    tooltip: "公司获得的实际收益",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
];
export default columns;
